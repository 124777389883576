<template>
  <section class="container-edit">
    <div class="home-apps">
      <home-region-application />
      <home-manager-application />
      <home-pricing-application />
      <home-avm-application-vue />
    </div>
  </section>
</template>

<script>
import HomeRegionApplication from "@/components/region/HomeRegionApplication.vue";
import HomeManagerApplication from "@/components/manager/HomeManagerApplication.vue";
import HomePricingApplication from "@/components/pricing/HomePricingApplication.vue";
import HomeAvmApplicationVue from '../components/avm/HomeAvmApplication.vue';
export default {
  components: {
    HomeRegionApplication,
    HomeManagerApplication,
    HomePricingApplication,
    HomeAvmApplicationVue
  },
};

</script>

<style lang="scss" scoped>
.container-edit {
  width: 90vw;
  max-width: 2400px;
  margin: 0 auto;
}
.home-apps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 30px;
  position: relative;
  margin: 30px auto 90px;
  width: 100%;
  z-index: 999;
  
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 40px 0;
    margin: 0;
    padding: 20px ;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
  
}

</style>
