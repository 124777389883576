<template>
  <div class="home-application p-5 mx-4">
    <h2 class="application-title mb-4">
      Dataland <br />
      Urban
    </h2>

    <div class="application-description mb-5">
      Tome as melhores decisões através do entendimento do entorno
    </div>
    
      <div v-if="permissions" class="application-actions">
        <a class="btn btn-primary w-50" href="/urban"> Abrir </a>
      </div>
      <button v-if="!permissions" disabled class="btn btn-primary w-50">
        Abrir
      </button>

      <!-- <button disabled class="btn btn-outline-primary ml-auto">
        Estudos salvos
      </button> -->
    
  </div>
</template>
<script>
const axios = require("axios").default;

export default {
  data() {
    return {
      permissions: false,
    };
  },
  mounted() {
    this.hasPermission = this.fetchHasManager();
  },
  methods: {
    async fetchHasManager() {
      let allpermission = this.$router.app.$session.get("permission");
      if (allpermission == undefined) {
        let res = await axios.get("/api/clients/aboutme", {
          headers: { email: this.$router.app.$session.get("email") },
        });
        this.$router.app.$session.set("permission", res.data.permissions);
        allpermission = this.$router.app.$session.get("permission");
      }

      this.permissions = allpermission.includes("APP_URBAN");
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-application {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid $gray-400;
  border-radius: 20px;
  box-shadow: 0px 15px 50px rgba(95, 95, 95, 0.25);
}

.application-title {
  font-size: 24px;
  line-height: 30px;
  color: $brand;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.application-description {
  font-style: italic;
  color: $gray-800;
}

.application-actions-region {
  display: flex;
  button {
    @media screen and (max-width: 720px) {
      margin-top: 10px;
      margin-left: 0 !important;
    }
  }
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
}
</style>
